import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  format,
  addMonths,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameDay,
} from "date-fns";
import { BsSearch } from "react-icons/bs";
import Config from "../utils/config";
import AgentDetailsModal from "../components/AgentDetailsModal";
import Spinner from "../components/Spinner";

const AdminAvailability = () => {
  const [agents, setAgents] = useState([]);
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(
    format(new Date(), "yyyy-MM")
  );
  
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchAgents();
  }, [selectedMonth]);

  useEffect(() => {
    filterAgents();
  }, [startDate, endDate, agents, searchQuery]);

  useEffect(() => {
    const monthStart = startOfMonth(new Date(selectedMonth));
    const monthEnd = endOfMonth(new Date(selectedMonth));
    setStartDate(format(monthStart, "yyyy-MM-dd"));
    setEndDate(format(monthEnd, "yyyy-MM-dd"));
  }, [selectedMonth]);

  const fetchAgents = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${Config.baseURL}/get-all-available-agent/${selectedMonth}`
      );
      console.log(response.data);
      setAgents(response.data);
    } catch (error) {
      console.error(
        "Une erreur s'est produite lors de la récupération des agents !",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const filterAgents = () => {
    let filtered = agents;

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      end.setHours(23, 59, 59, 999);  

      filtered = filtered.filter((agent) => {
        return agent.availability.some((avail) => {
          const availDate = new Date(avail.date);
          return (
            isSameDay(availDate, start) ||
            (availDate >= start && availDate <= end)
          );
        });
      });
    }

    if (searchQuery) {
      const normalizedQuery = searchQuery.toLowerCase().trim();
      filtered = filtered.filter(
        (agent) =>
          agent.agent.firstName.toLowerCase().includes(normalizedQuery) ||
          agent.agent.lastName.toLowerCase().includes(normalizedQuery) ||
          agent.agent.email.toLowerCase().includes(normalizedQuery)
      );
    }

    setFilteredAgents(filtered);
  };

  const handleAgentClick = async (agentId) => {
    try {
      const response = await axios.get(`${Config.baseURL}/agent/${agentId}`);
      console.log(response.data);
      setSelectedAgent(response.data);
      setModalIsOpen(true);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des détails de l'agent :",
        error
      );
    }
  };

  if (loading) {
    return <Spinner />;
  }

  const dayNightIndicators = (
    <>
      <h1 className="text-3xl font-semibold text-gray-500 mt-8 mb-16">
        Disponibilité des Agents
      </h1>

      <div className="flex justify-between items-center mb-4 rounded-lg">
        {/* Left side with colors */}
        <div className="flex space-x-4">
          <div className="flex items-center">
            <span className="inline-block w-4 h-4 rounded-full bg-[#FAB615] mr-2"></span>
            <span className="font-semibold">Jour</span>
          </div>
          <div className="flex items-center">
            <span className="inline-block w-4 h-4 rounded-full bg-[#B0B0B0] mr-2"></span>
            <span className="font-semibold">Nuit</span>
          </div>
        </div>

        {/* Right side with search input */}
        <div className="flex items-center">
          <div className="relative">
            <input
              type="text"
              id="search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Rechercher..."
              className="p-2 border rounded mr-2 w-64"
              style={{ minWidth: "10rem" }}
            />
            <BsSearch className="absolute right-4 w-6 h-6 top-2 text-gray-400 pointer-events-none" />
          </div>
        </div>
      </div>
    </>
  );

  const renderAgentTable = () => {
    return (
      <div className="w-full overflow-x-auto">
        <table className="w-full table-auto bg-white shadow-md rounded-lg">
          <thead className="bg-gray-200">
            <tr className="text-left">
              <th className="border px-4 py-2 text-md font-semibold text-gray-700 whitespace-nowrap">
                Prénom
              </th>
              <th className="border px-4 py-2 text-md font-semibold text-gray-700 whitespace-nowrap">
                Nom
              </th>
              <th className="border px-4 py-2 text-md font-semibold text-gray-700 whitespace-nowrap">
                Email
              </th>
              <th className="border px-4 py-2 text-md font-semibold text-gray-700 whitespace-nowrap">
                Poste
              </th>
              <th className="border px-4 py-2 text-md font-semibold text-gray-700 whitespace-nowrap">
                Matricules
              </th>
              <th className="border px-4 py-2 text-md font-semibold text-gray-700 whitespace-nowrap">
                Disponibilités
              </th>
              <th className="border px-8 py-2 text-md font-semibold text-gray-700 whitespace-nowrap">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredAgents.map((agent) => (
              <tr key={agent._id}>
                <td className="border px-4 py-2 text-md text-gray-700 whitespace-nowrap">
                  {agent.agent.firstName}
                </td>
                <td className="border px-4 py-2 text-md text-gray-700 whitespace-nowrap">
                  {agent.agent.lastName}
                </td>
                <td className="border px-4 py-2 text-md text-gray-700 whitespace-nowrap">
                  {agent.agent.email}
                </td>
                <td className="border px-4 py-2 text-md text-gray-700 whitespace-nowrap">
                  {agent.agent.poste}
                </td>
                <td className="border px-4 py-2 text-md text-gray-700 whitespace-nowrap">
                  {agent.agent.matricules ? agent.agent.matricules : "N/A"}
                </td>
                <td className="border px-4 py-2 text-md text-gray-700 whitespace-nowrap">
                  {agent.availability
                    .reduce((rows, avail, index) => {
                      if (index % 6 === 0) rows.push([]);
                      rows[rows.length - 1].push(avail);
                      return rows;
                    }, [])
                    .map((row, rowIndex) => (
                      <div key={rowIndex} className="flex mb-2">
                        {row.map((avail, index) => (
                          <span
                            key={index}
                            className="inline-block relative mr-2"
                            style={{
                              width: "1.8rem",
                              height: "1.8rem",
                              borderRadius: "50%",
                              background:
                                avail.day && avail.night
                                  ? "linear-gradient(to right, #F59E0B 50%, #B0B0B0 50%)"
                                  : avail.day
                                  ? "#F59E0B"
                                  : avail.night
                                  ? "#B0B0B0"
                                  : "transparent",
                            }}
                          >
                            <span
                              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                              style={{
                                color: "#000",
                                fontWeight: "bold",
                                fontSize: "0.75rem",
                              }}
                            >
                              {format(new Date(avail.date), "dd")}
                            </span>
                          </span>
                        ))}
                      </div>
                    ))}
                </td>

                <td className="border text-left px-4 py-2 text-md whitespace-nowrap">
                  <button
                    className="text-blue-500 text-center py-2 px-4 rounded"
                    onClick={() => handleAgentClick(agent._id)}
                  >
                    Voir plus
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const formatMonthInFrench = (date) => {
    const options = { year: "numeric", month: "long" };
    return date.toLocaleDateString("fr-FR", options);
  };

  return (
    <div className="flex flex-col items-center justify-top min-h-screen p-4 bg-gray-50">
      <div className="w-full min-w-7xl">
        {dayNightIndicators}

        <label htmlFor="month" className="mr-2">
          Filtrer par mois :
        </label>
        <input
          type="month"
          id="month"
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
          className="p-2 border rounded mb-4"
          placeholder={formatMonthInFrench(new Date())}
        />
        <div className="mb-4">
          <label htmlFor="startDate" className="mr-2">
            Date de début :
          </label>
          <input
            type="date"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="p-2 border rounded mb-4"
          />
          <label htmlFor="endDate" className="mr-2 ms-4">
            Date de fin :
          </label>
          <input
            type="date"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="p-2 border rounded mb-4"
          />
        </div>
        {renderAgentTable()}
      </div>

      {modalIsOpen && (
        <AgentDetailsModal
          isOpen={modalIsOpen}
          closeModal={() => setModalIsOpen(false)}
          agent={selectedAgent}
          selectedMonth={selectedMonth}
        />
      )}
    </div>
  );
};

export default AdminAvailability;
